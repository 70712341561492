.ms-Grid-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

body {
  background-color: #faf9f8;
}

/* TODO use theme */
/* Override the React default field error styling by thickening the error border more */
.pickerErrorBorder .ms-BasePicker-text,
.errorBorder,
.datePickerErrorBorder .ms-TextField-fieldGroup,
.basicDatePickerErrorBorder .ms-Dropdown-title,
[class*='titleIsError'],
  /* Drop down */ .ms-TextField [class*='invalid'] {
  border: 1px solid #ce0404 !important;
}
