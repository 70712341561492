$breakpoints: (
  'sm': '(max-width: 639px)',
  'md': '(min-width: 640px) and (max-width: 1199px)',
  'lg': '(min-width: 1200px)',
);

.grid {
  display: grid;

  @for $index from 1 through 5 {
    &.col-gap-#{$index} {
      gap: ($index * 10px);
    }

    &.row-gap-#{$index} {
      row-gap: ($index * 10px);
    }
  }
}

@each $breakpoint, $query in $breakpoints {
  @media #{$query} {
    .col {
      &.#{$breakpoint}-hidden {
        display: none;
      }
    }

    @for $index from 1 through 12 {
      .grid {
        &.#{$breakpoint}-#{$index} {
          grid-template-columns: repeat($index, 1fr);
        }
      }

      .col {
        &.#{$breakpoint}-#{$index} {
          grid-column: span $index;

          @for $offset from 1 through 12 {
            &.start-#{$offset} {
              grid-column: $offset / span $index;
            }
          }
        }
      }
    }
  }
}
