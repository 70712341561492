.rmap-lic-popup {
  color: blue;
  max-width: 350px;
  max-height: 500px;
  position: absolute;
  z-index: 1000;
  left: 18px;
  top: 150px;
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 3px;
  margin-bottom: 3px;
}

.rmap-lic-popup-grid {
  display: grid;
  grid-template-columns: max-content auto;
}

.rmap-lic-popup-grid > div {
  padding: 1px 1px 1px 3px;
}
.rmap-lic-popup-grid > div:nth-child(4n + 3),
.rmap-lic-popup-grid > div:nth-child(4n + 4) {
  background: rgba(0, 0, 0, 0.07);
}

.rmap-lic-popup-grid > div:nth-child(even) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.rmap-lic-popup-grid > div:nth-child(odd) {
  font-weight: bold;
}

.rmap-lic-popup-det {
  float: right;
  color: darkslateblue;
}

.rmap-control-panel {
  position: absolute;
  z-index: 1000;
  left: 18px;
  top: 45px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 3px;
}
.rmap-control-panel div {
  padding: 5px;
}
