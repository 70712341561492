.ui-tagPicker-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  min-width: 180px;
  min-height: 30px;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 2px;

  &.ui-tagPicker-readOnly {
    border: none;

    .ui-tagPicker-tagItemText {
      text-align: center;
    }
  }

  .ui-tagPicker-tagInput {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    height: 30px;
    border: none;
    flex-grow: 1;
    outline: none;
    padding: 0px 6px;
    align-self: flex-end;
    border-radius: 2px;
    background-color: transparent;
    color: rgb(65, 64, 66);
  }

  .ui-tagPicker-tagsWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .ui-tagPicker-tagItem {
    font-family: 'Fira Sans';
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    outline: transparent;
    position: relative;
    box-sizing: content-box;
    flex-shrink: 1;
    margin: 2px;
    height: 26px;
    line-height: 26px;
    cursor: default;
    display: flex;
    flex-wrap: nowrap;
    max-width: 300px;
    min-width: 0px;
    border-radius: 2px;
    color: rgb(65, 64, 66);
    background: rgb(243, 242, 241);
    user-select: none;

    .ui-tagPicker-tagItemText {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 30px;
      margin: 0px 8px;
    }

    .ui-tagPicker-tagItemClose {
      outline: transparent;
      position: relative;
      font-family: 'Fira Sans';
      -webkit-font-smoothing: antialiased;
      font-size: 10px;
      font-weight: 400;
      font-style: normal;
      box-sizing: border-box;
      border: none;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      padding: 0px 4px;
      border-radius: 0px 2px 2px 0px;
      width: 30px;
      height: 100%;
      background-color: transparent;
      color: rgb(96, 94, 92);
      flex: 0 0 auto;
      user-select: none;

      &:hover {
        color: rgb(50, 49, 48);
        background: rgb(225, 223, 221);
      }
    }
  }
}
